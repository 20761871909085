const Start = () => {
  return (
    <a
      href="#start"
      className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
    >
      Start
    </a>
  );
};

export default Start;
